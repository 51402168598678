.Editor {
  font-family: sans-serif;
  display: block;
}
/* Override default */
.Draftail-Toolbar {
  background: transparent !important;
  border: none !important;
}
/* Override default */
.Draftail-Editor {
  border: none !important;
}
/* Override default */
.public-DraftEditor-content {
  font-family: Georgia, "Times New Roman", Times, serif !important;
}
/* Override default */
.public-DraftEditorPlaceholder-inner {
  font-family: Georgia, "Times New Roman", Times, serif !important;
  text-align: left;
}

.Draftail-Editor .public-DraftEditor-content, .Draftail-Editor .public-DraftEditorPlaceholder-root {
  padding: 0;
}

.Draftail-Editor h1 {
  font-size: 2.25rem;
}

.Draftail-Editor h2 {
  font-size: 1.875rem;
}

.Draftail-Editor h3 {
  font-size: 1.5rem;
}